<script>
import PrintPublicationNew from './PrintPublicationNew'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'PrintPublicationEdit',
  extends: PrintPublicationNew,
  methods: {
    getPrintPublication () {
      this.$store.dispatch('printPublication/fetchOne', this.$route.params.id)
        .then(() => {
          this.printPublication = this.$store.getters['printPublication/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid || !this.isImageValid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.printPublication.image = null
      if (this.$store.getters['printPublication/image']) {
        this.printPublication.image = this.$store.getters['printPublication/image'].id
      }
      this.$store.dispatch('printPublication/update', this.printPublication)
        .then(() => {
          this.printPublication = this.$store.getters['printPublication/detail']
          if (this.$store.getters['printPublication/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['printPublication/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getPrintPublication()
  }
}
</script>
