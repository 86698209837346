import TinyMceParser from '../TinyMceParser'

const TINYMCE_SETTINGS_STYLE_FORMATS = [
  {
    title: 'Inline',
    items: [
      { title: 'Bold', icon: 'bold', format: 'bold' },
      { title: 'Italic', icon: 'italic', format: 'italic' }
    ]
  }
]

const getConfig = () => {
  return {
    element_format: 'html',
    entity_encoding: 'named',
    entities: '160,nbsp',
    theme: 'modern',
    height: 300,
    autoresize_min_height: 300,
    skin_url: '/tinymce/skins/lightgray',
    content_css: [
      '/tinymce/main.css',
      '/tinymce/font-awesome.css'
    ],
    language_url: '/tinymce/langs/sk.js',
    valid_children: '-p[div],-div[p]',
    plugins: [
      'paste wordcount'
    ],
    default_link_target: '_blank',
    paste_as_text: true,
    paste_data_images: false,
    style_formats: TINYMCE_SETTINGS_STYLE_FORMATS,
    menubar: false,
    advlist_bullet_styles: 'default',
    advlist_number_styles: 'default,lower-alpha',
    toolbar: [
      'custom_button_unordered_list bold italic'
    ],
    paste_preprocess: function (plugin, args) {
      let content = args.content
      content = TinyMceParser.removeElement(content, 'pre', true)
      content = TinyMceParser.removeSuccElement(content, 'h1', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h2', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h3', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h4', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h5', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h6', 'a', true)
      args.content = content
    },
    setup: editor => {
      editor.addButton('custom_button_unordered_list', {
        text: '',
        icon: 'bullist',
        tooltip: 'Bullet list',
        onclick: function () {
          editor.execCommand('InsertUnorderedList')
        }
      })
    }
  }
}

export default {
  getConfig
}
